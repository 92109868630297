import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/NoteLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "what-makes-notes-good",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-makes-notes-good",
        "aria-label": "what makes notes good permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`What makes notes good?`}</h2>
    <h3 {...{
      "id": "zettelkasten-principles",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#zettelkasten-principles",
        "aria-label": "zettelkasten principles permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a><a parentName="h3" {...{
        "href": "https://zettelkasten.de/posts/overview/"
      }}>{`Zettelkasten Principles`}</a></h3>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Principle of Atomicity`}</strong>{`: put things that belong together into a single
note, give it an ID, but limit its content to that single topic.`}</p>
    </blockquote>
    <h3 {...{
      "id": "andys-evergreen-notes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#andys-evergreen-notes",
        "aria-label": "andys evergreen notes permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Andy’s Evergreen Notes`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://notes.andymatuschak.org/Evergreen_notes_should_be_atomic"
        }}>{`Evergreen notes should be atomic`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://notes.andymatuschak.org/Evergreen_notes_should_be_concept-oriented"
        }}>{`Evergreen notes should be concept oriented`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://notes.andymatuschak.org/Evergreen_notes_should_be_densely_linked"
        }}>{`Evergreen notes should be densely linked`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      